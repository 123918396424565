import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet-async";

const NotFound = () => {

    return (
        <div>
            <Helmet>
                <title>404 Page not found</title>
            </Helmet>

            <div className="wrapper">
                <div className="section sec-one">

                    <div className="program">
                        <h1>404 - Page not found</h1> <Link to="/" style={{ color: 'white' }}>Go Back</Link>
                    </div>

                </div>


            </div>
        </div>
    )
}

export default NotFound