import axios from 'axios';

export const sendEmail = async (userData: Object) => {
    try {
        const res = await sendRequest(`${process.env.REACT_APP_API}/contact-form-items`, {
            method: 'POST',
            data: userData

        });
        return res;
    } catch (error) {
        throw error;
    }
};

export const getGallery = async (id: number | undefined) => {
    try {
        const res = await sendRequest(`${process.env.REACT_APP_API}/galleries/${id}`, {
            method: 'GET',
        });
        return res;
    } catch (error) {
        throw error;
    }
};

export const getGalleries = async () => {
    try {
        const res = await sendRequest(`${process.env.REACT_APP_API}/galleries/`, {
            method: 'GET',
        });
        return res;
    } catch (error) {
        throw error;
    }
};

export const getPlayers = async () => {
    try {
        const res = await sendRequest(`${process.env.REACT_APP_API}/players/`, {
            method: 'GET',
        });
        return res;
    } catch (error) {
        throw error;
    }
};

export const getCoaches = async () => {
    try {
        const res = await sendRequest(`${process.env.REACT_APP_API}/coaches/`, {
            method: 'GET',
        });
        return res;
    } catch (error) {
        throw error;
    }
};

export const getNews = async () => {
    try {
        const res = await sendRequest(`${process.env.REACT_APP_API}/news-items?_sort=releseDate:asc`, {
            method: 'GET',
        });
        return res;
    } catch (error) {
        throw error;
    }
};

export const getOneNews = async (id: number | undefined) => {
    try {
        const res = await sendRequest(`${process.env.REACT_APP_API}/news-items/${id}`, {
            method: 'GET',
        });
        return res;
    } catch (error) {
        throw error;
    }
};

export const sendRequest = async (url: string, options: Object) => {

    try {
        return await axios(url, options);
    } catch (error) {
        if (error.status === 401 || error.status === 403) {
            throw error;
        }
        if (error.response) {
            console.log(error);
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            //422 handle to be done
            throw new Error(error.response.data.message);
        } else {
            // Something happened in setting up the request that triggered an Error
            throw new Error(error.message);
        }
    }
};
