import React, {useState, useEffect, MouseEvent} from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import {Helmet} from "react-helmet-async";
import {useTranslation} from "react-i18next";
import {Link} from 'react-router-dom'
import {getNews} from '../services/Services'
import moment from 'moment';
import Loader from '../utils/Loader'
import i18next from "i18next";

const newsPerPage = 3;
let arrayForHoldingNews: object[] = []

interface News {
    id?: number
    title?: string
    titleRS?: string
    releseDate?: string
    coverImage?: {
        formats?: {
            small?: {
                url: string | null
            }
        }
        url?: string | null
    }
}

const NewsPage = () => {

    const {t} = useTranslation();
    const [news, setNews] = useState<object[]>([])
    const [newsToShow, setNewsToShow] = useState<object[]>([]);
    const [next, setNext] = useState(3);
    const [isLoading, setIsLoading] = useState<Boolean>(true)

    const loopWithSlice = (start: number, end: number) => {

        const slicedNews = news.slice(start, end);
        arrayForHoldingNews = [...arrayForHoldingNews, ...slicedNews];
        setNewsToShow(arrayForHoldingNews);
    };

    const handleShowMoreNews = (event: MouseEvent) => {
        loopWithSlice(next, next + newsPerPage);
        setNext(next + newsPerPage);
        event.preventDefault()
    };

    useEffect(() => {
        getNews()
            .then(res => {
                    const response = res && res.data.reverse()
                    setNews([...response])
                    const slicedNews = response.slice(0, newsPerPage);
                    arrayForHoldingNews = [...arrayForHoldingNews, ...slicedNews];
                    setNewsToShow(arrayForHoldingNews);
                    setIsLoading(false)
                }
            )
            .catch(err => console.log(err))

        return () => {
            arrayForHoldingNews = []
        }
    }, [])

    return (<>
            <Helmet>
                <title>{`${t('Tipsarevic Tennis Academy | Vesti')}`}</title>
            </Helmet>
            <Header cssClass={"section-one"}/>
            <div className="wrapper-inner section-one otherpages">
                <div className="content">
                    <h1>{t('Vesti')}</h1>
                    <div className="news-list">
                        {!isLoading ? newsToShow.map((data: News, index: number) => (
                                <div key={index} className="news">
                                    <div className="news-photo">
                                        <Link to={`/${i18next.language}/${t("vesti")}/${data.id}`}><img
                                            src={`${process.env.REACT_APP_API}${data?.coverImage?.formats?.small ? data.coverImage.formats.small.url : data?.coverImage?.url}`}
                                            alt=""/></Link>
                                    </div>
                                    <div className="news-title">
                                        <Link to={`/${i18next.language}/${t("vesti")}/${data.id}`}>
                                            <h6>{i18next.language === 'en' ? data.title : data.titleRS}</h6>
                                        </Link>
                                        <span className="news-date">{moment(data.releseDate).format("DD.MM.YYYY")}</span>
                                    </div>
                                </div>
                            ))
                            :
                            <Loader/>
                        }

                        <a href="/#" onClick={handleShowMoreNews} className="load-more">{t('Učitaj više vesti')}</a>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default NewsPage
