import React, { useState, useEffect } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom'
import { getOneNews, getNews } from '../services/Services'
import moment from 'moment'
import ReactMarkdown from 'react-markdown'
import Loader from '../utils/Loader'
import i18next from "i18next";

interface NewsID {
    match?: {
        params?: {
            id?: number
        }
    }
}

const NewsItem = (props: NewsID) => {

    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState<Boolean>(true)

    interface OneNews {
        title: string
        titleRS: string
        releseDate: string
        body: string
        bodyRS: string
        coverImage: {
            url: string
        }
    }

    interface News {
        id?: number
        title?: string
        titleRS?: string
        releseDate?: string
        coverImage?: {
            formats?: {
                small?: {
                    url: string | null
                }
            }
            url?: string | null
        }
    }

    const [oneNews, setOnewNews] = useState<OneNews | undefined>()
    const [news, setNews] = useState<object[]>()

    useEffect(() => {
        getOneNews(props?.match?.params?.id)
            .then(res => {
                const response = res && res.data
                setOnewNews(response)
                setIsLoading(false)
            }
            )
            .catch(err => console.log(err))

        getNews()
            .then(res => {
                const response = res && res.data.reverse()
                setNews([...response])
            })


    }, [props?.match?.params?.id])

    return (<>
        <Helmet>
            <title>{`${t('Tipsarevic Tennis Academy | Vest 1 Naslov vesti')}`}</title>
        </Helmet>
        <Header cssClass={"section-one"} />
        <div className="wrapper-inner otherpages section-one">
            {!isLoading ? oneNews && <> <div className="content">
                <Link to={`/${i18next.language}/${t("vesti")}`} className="back-button">{t('Nazad na vesti')}</Link>
                <h1>{i18next.language === 'en' ? oneNews.title : oneNews.titleRS}<span className="news-date">{moment(oneNews.releseDate).format("DD.MM.YYYY")}</span>
                </h1>
                <div className="news-content">
                    <img className="fl-left" src={`${process.env.REACT_APP_API}${oneNews.coverImage.url}`} width="500" alt="" />
                    <ReactMarkdown>{i18next.language === 'en' ? oneNews.body : oneNews.bodyRS}</ReactMarkdown>
                </div>
            </div>
                <div className="content">
                    <div className="more-news-list">
                        <h3>{t('Ostale vesti')}</h3>
                        {news && news.map((data: News, index: number) => {
                            if (index > 2) return null
                            return (
                                <Link to={`/${i18next.language}/${t("vesti")}/${data.id}`}><div key={index} className="news">
                                    <div className="news-photo">
                                        <img src={`${process.env.REACT_APP_API}${data?.coverImage?.formats?.small ? data.coverImage.formats.small.url : data?.coverImage?.url}`} alt="" />
                                    </div>
                                    <div className="news-title">
                                        <h6>{i18next.language === 'en' ? data.title : data.titleRS}</h6>
                                        <span className="news-date">{moment(data.releseDate).format("DD.MM.YYYY")}</span>
                                    </div>
                                </div> </Link>
                            )
                        })
                        }
                    </div>
                </div>
            </>

                :
                <Loader />}

        </div>
        <Footer />
    </>
    )
}

export default NewsItem
