import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Helmet } from "react-helmet-async";
import { useTranslation, Trans } from "react-i18next";
import { getPlayers } from '../services/Services'
import Loader from '../utils/Loader'

const Players = () => {

    const { t } = useTranslation();
    const [players, setPlayers] = useState<object[]>()
    const [isLoading, setIsLoading] = useState<Boolean>(true)

    interface Players {
        firstName?: string
        lastName?: string
        picture?: {
            url: string | null
        }
        birthYear?: string
        currentPosition?: null | number
        bestPosition?: null | number
        country?: string
        flag?: {
            url: string | null
        },
        url?: string | undefined
    }

    /* const checkChar = (props: number | null | undefined): string => {
         const string = props?.toString()
         if (string?.length === 3) return "wrap-stats three-char"
         if (string?.length === 4) return "wrap-stats four-char"
         return "wrap-stats"
     } */

    useEffect(() => {
        getPlayers()
            .then(res => {
                const response = res && res.data
                response.sort((a: { sortID: number | null }, b: { sortID: number | null }) => (a.sortID != null ? a.sortID : Infinity) - (b.sortID != null ? b.sortID : Infinity))
                setPlayers([...response])
                setIsLoading(false)
            }
            )
            .catch(err => console.log(err))

    }, [])

    return (<>
        <Helmet>
            <title>{`${t('Tipsarevic Tennis Academy | Igrači')}`}</title>
            <meta name="description"
                content={t('igraciText')} />
        </Helmet>
        <Header cssClass={"section-one"} />
        <div className="wrapper-inner section-one otherpages">
            <div className="content">
                <h1>{t('Igrači')}</h1>
                <p>{t('igraciText')}</p>
            </div>
            <div className="content">
                <div className="players">
                    {!isLoading ? players && players.map((data: Players, index: number) => (
                        <div key={index} className="player">
                            <div className="pl-photo">
                                <a target="_blank" rel="noopener noreferrer" href={`${data.url}`}>
                                    <img src={`${process.env.REACT_APP_API}${data.picture?.url}`} alt="" />
                                </a>
                                <div className="stats stats-player">
                                    {/*      <div className="stats-holder">
                                        <div className="wrap-stats">
                                            <div className="age">{data.birthYear && moment(data.birthYear, "YYYY").fromNow().split(" ")[0]}</div>
                                        </div>
                                        <span className="label">{t('Godina')}</span>
                    </div> */}
                                    {/*<div className="stats-holder">
                                        <div className={checkChar(data.currentPosition)}>
                                            <div className="rank">{data.currentPosition}</div>
                                        </div>
                                        <span className="label">Rang</span>
                                    </div>*/}
                                    <div className="stats-holder">
                                        <div className="rank">{data.bestPosition ?? "N/A"}</div>
                                        <span className="label">
                                            {t('Najbolji')}
                                            <br />
                                            {t('rang')}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="pl-data">
                                <span className="name-holder">
                                    <span className="name"><a target="_blank" rel="noopener noreferrer" href={`${data.url}`}>{data.firstName}</a></span>
                                    <span className="name"><a target="_blank" rel="noopener noreferrer" href={`${data.url}`}>{data.lastName}</a></span>
                                </span>
                                <span className="country"><img src={`${process.env.REACT_APP_API}${data.flag?.url}`} alt="" /><Trans>{data.country}</Trans></span>

                            </div>
                        </div>
                    )) :
                        <Loader />}
                </div>
            </div>
        </div>
        <Footer />
        {/* Use <Footer /> component */}
    </>
    )
}

export default Players
