/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import { useHistory, useLocation } from 'react-router-dom';

interface Props {
    cssClass?: string;
    activeLang?: String;
    setActiveLang: any;
}
const NavBar = ({ cssClass, activeLang, setActiveLang }: Props) => {
    const { t } = useTranslation();
    let history = useHistory();
    let location = useLocation();

    return (
        <nav className={cssClass}>
            <section className='nav-logo'>
                <Link to='/'></Link>
            </section>
            <section className='nav-programs'>
                <p className='nav-title'>{t('Programi')}</p>
                <ul>
                    <li>
                        <Link
                            to={`/${i18next.language}/${t(
                                'takmicarskiprogram',
                            )}`}
                        >
                            {t('Takmičarski program')}
                        </Link>
                    </li>
                    <li>
                        <Link
                            to={`/${i18next.language}/${t('skolskiprogram')}`}
                        >
                            {t('Škola tenisa')}
                        </Link>
                    </li>
                    <li>
                        <Link
                            to={`/${i18next.language}/${t(
                                'rekreativniprogram',
                            )}`}
                        >
                            {t('Rekreativni tenis')}
                        </Link>
                    </li>
                </ul>
            </section>
            <section className='nav-academy'>
                <p className='nav-title'>{t('Akademija')}</p>
                <ul>
                    <li>
                        <Link to={`/${i18next.language}/${t('nasaprica')}`}>
                            {t('Naša priča')}
                        </Link>
                    </li>
                    <li>
                        <Link to={`/${i18next.language}/${t('igraci')}`}>
                            {t('Igrači')}
                        </Link>
                    </li>
                    <li>
                        <Link to={`/${i18next.language}/${t('tim')}`}>
                            {t('Tim')}
                        </Link>
                    </li>
                </ul>
            </section>
            <section className='nav-info'>
                <p className='nav-title'>{t('Informacije')}</p>
                <ul>
                    <li>
                        <Link to={`/${i18next.language}/${t('vesti')}`}>
                            {t('Vesti')}
                        </Link>
                    </li>
                    <li>
                        <Link to={`/${i18next.language}/${t('galerije')}`}>
                            {t('Galerije')}
                        </Link>
                    </li>
                    <li>
                        <Link to={`/${i18next.language}/${t('kontakt')}`}>
                            {t('Kontakt')}
                        </Link>
                    </li>
                </ul>
            </section>
            <section className='nav-location'>
                <p className='nav-title'>{t('Lokacije')}</p>
                <div className='location'>
                    <h6>{t('Olimp')}</h6>
                    <p>
                        <Trans i18nKey='kontaktAdresaTelefonOlimp'>
                            Vjekoslava Kovača 11,
                            <br /> 11000 Beograd <br />
                            +381 (11) 7357679
                        </Trans>
                    </p>
                    <a href='mailto:office@tipsarevicacademy.com'>
                        office@tipsarevicacademy.com
                    </a>
                </div>
                <div className='location'>
                    <h6>SC 11. April</h6>
                    <p>
                        <Trans i18nKey='kontaktAdresaTelefonSC'>
                            Autoput za Zagreb 2, <br /> 11000 Beograd <br />
                            +381 (64) 1191272
                        </Trans>
                    </p>
                    <a href='mailto:bojan@tipsarevicacademy.com'>
                        bojan@tipsarevicacademy.com
                    </a>
                </div>
            </section>
            <div className='header-right mobile'>
                <ul className='social'>
                    <li className='facebook'>
                        <a
                            target='_blank'
                            rel='noopener noreferrer'
                            href='https://www.facebook.com/tipsarevictennisacademy/'
                        >
                            Facebook
                        </a>
                    </li>
                    <li className='instagram'>
                        <a
                            target='_blank'
                            rel='noopener noreferrer'
                            href='https://www.instagram.com/tipsarevictennisacademy'
                        >
                            Instagram
                        </a>
                    </li>
                    {/*<li className="youtube"><a target="_blank" rel="noopener noreferrer" href="http://www.tipsarevicacademy.com/#0">Youtube</a></li>*/}
                </ul>
                <div className='language'>
                    <a
                        href=''
                        className={activeLang === 'en' ? 'active' : ''}
                        onClick={(e: any) => {
                            i18next.changeLanguage('en');
                            setActiveLang('en');
                            const url = location.pathname.toString();

                            if (url.charAt(1) === 'e') return;

                            if (url === '/') return e.preventDefault();
                            else {
                                const urlNew = url.slice(4);
                                return history.push('/en/' + t(urlNew));
                            }
                        }}
                    >
                        Eng
                    </a>
                    <a
                        href=''
                        className={activeLang === 'sr' ? 'active' : ''}
                        onClick={(e: any) => {
                            i18next.changeLanguage('sr');
                            setActiveLang('sr');

                            const url = location.pathname.toString();
                            if (url === '/') return e.preventDefault();
                            else {
                                const urlNew = url.slice(4);
                                return history.push('/sr/' + t(urlNew));
                            }
                        }}
                    >
                        Srb
                    </a>
                    <ul>
                        <li>Srpski</li>
                        <li>English</li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default NavBar;
