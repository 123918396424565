import React, { useState, useEffect } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom'
import { getGalleries } from '../services/Services'
import Loader from '../utils/Loader'
import i18next from "i18next";

const Galleries = () => {

    const { t } = useTranslation();

    const [coverImages, setCoverImages] = useState<object[]>()
    const [isLoading, setIsLoading] = useState<Boolean>(true)

    interface Image {
        id: number,
        title: string,
        titleRS: string,
        sortID: number,
        coverImage: {
            url: string,
            caption: string,
            formats: {
                small: {
                    url: string,
                    width: string,
                    height: string
                }
                coverImage: {
                    caption: string,
                    width: string,
                    height: string,
                }
            }
        }
    }

    interface CoverImage {
        caption?: string
        id?: number
        thumbnail?: string
        title?: string
        titleRS?: string
    }

    useEffect(() => {
        getGalleries()
            .then(res => {
                const response = res && res.data && res.data.sort((a: { sortID: number | null }, b: { sortID: number | null }) => (a.sortID != null ? a.sortID : Infinity) - (b.sortID != null ? b.sortID : Infinity)).map((data: Image, index: number) => ({
                    key: index,
                    id: data.id,
                    src: `${process.env.REACT_APP_API}${data.coverImage.url}`,
                    thumbnail: `${process.env.REACT_APP_API}${data.coverImage.formats.small.url}`,
                    thumbnailWidth: data.coverImage.formats.small.width,
                    thumbnailHeight: data.coverImage.formats.small.height,
                    title: data.title,
                    titleRS: data.titleRS,
                }))
                setCoverImages([...response])
                setIsLoading(false)
            }
            )
            .catch(err => console.log(err))

    }, [])

    return (<>
        <Helmet>
            <title>{`${t('Tipsarevic Tennis Academy | Galerije')}`}</title>
        </Helmet>
        <Header cssClass={"section-one"} />
        <div className="wrapper-inner otherpages section-one">
            <div className="content">
                <h1>{t('Galerije')}</h1>
                <div className="gallery-albums">
                    {!isLoading ? coverImages && coverImages.map((data: CoverImage, index: number) => {
                        return (
                            <div key={index} className="album">
                                <h6>{i18next.language === 'en' ? data.title : data.titleRS}</h6>
                                <Link to={`/${i18next.language}/${t("galerije")}/${data.id}`} className="album-cover">
                                    <img src={data.thumbnail} alt="" />
                                </Link>
                            </div>
                        )
                    })
                        :
                        <Loader />}
                </div>
            </div>
        </div>

        <Footer />
    </>
    )
}

export default Galleries
