import React, { MouseEvent } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet-async';
import { useTranslation, Trans } from 'react-i18next';
import Application from '../components/Aplication';

const Contact = () => {
    const { t } = useTranslation();
    const [openedMap, setOpenedMap] = React.useState<String>('olimp');

    return (
        <>
            <Helmet>
                <title>{`${t('Tipsarevic Tennis Academy | Kontakt')}`}</title>
                <meta
                    name='viewport'
                    content='width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0'
                />
            </Helmet>
            <Header cssClass={'section-one'} />
            <div className='wrapper-inner section-one otherpages'>
                <div className='content'>
                    <h1>{t('Kontakt')}</h1>
                    <Application type={t('Kontakt forma')} isContact={true} />
                    <p> </p>
                </div>

                <div className='locations content'>
                    <div className='location'>
                        <h2>Olimp</h2>
                        <span className='subtitle'>
                            {t(
                                'NAŠA GLAVNA LOKACIJA JE U SKLOPU SC "OLIMP-ZVEZDARA',
                            )}
                        </span>
                        <p>
                            <Trans i18nKey='kontaktAdresaTelefonOlimp'>
                                Vjekoslava Kovaca 11,
                                <br />
                                11000 Beograd <br />
                                +381 (11) 7357679
                            </Trans>
                        </p>
                        <a href='mailto:office@tipsarevicacademy.com'>
                            office@tipsarevicacademy.com
                        </a>
                        <a
                            className={
                                openedMap === 'olimp'
                                    ? 'show-map show-map-off'
                                    : 'show-map'
                            }
                            href='/#'
                            onClick={(e: MouseEvent) => {
                                e.preventDefault();
                                setOpenedMap('olimp');
                            }}
                        >
                            {t('Prikaži mapu ove lokacije')}
                        </a>
                    </div>
                    <div className='location'>
                        <h2>SC 11. April</h2>
                        <span className='subtitle'>
                            {t('LOKACIJA NA KOJOJ VODIMO SVOJU TENISKU ŠKOLU')}
                        </span>
                        <p>
                            <Trans i18nKey='kontaktAdresaTelefonSC'>
                                Autoput za Zagreb 2,
                                <br />
                                11000 Beograd
                                <br />
                                +381 (64) 1191272
                            </Trans>
                        </p>
                        <a href='mailto:bojan@tipsarevicacademy.com'>
                            bojan@tipsarevicacademy.com
                        </a>
                        <a
                            className={
                                openedMap === 'sc'
                                    ? 'show-map show-map-off'
                                    : 'show-map'
                            }
                            href='/#'
                            onClick={(e: MouseEvent) => {
                                e.preventDefault();
                                setOpenedMap('sc');
                            }}
                        >
                            {t('Prikaži mapu ove lokacije')}
                        </a>
                    </div>
                </div>
                <div
                    className={
                        openedMap === 'olimp'
                            ? 'map map-one'
                            : 'map map-one hidden-map'
                    }
                >
                    <iframe
                        title='map1'
                        src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2831.3239001007555!2d20.50714161575046!3d44.79458728589278!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475a7082b9ecdc13%3A0x2b8d47b78cd1e668!2sVjekoslava%20Kova%C4%8Da%2011%2C%20Beograd%2011000!5e0!3m2!1sen!2srs!4v1604784791066!5m2!1sen!2srs'
                        width='1000'
                        height='600'
                        frameBorder='0'
                        style={{ border: 0 }}
                        allowFullScreen={false}
                        aria-hidden='false'
                        tabIndex={0}
                    ></iframe>
                </div>
                <div
                    className={
                        openedMap === 'sc'
                            ? 'map map-two'
                            : 'map map-two hidden-map'
                    }
                >
                    <iframe
                        title='map2'
                        src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2829.775385500962!2d20.392122415751192!3d44.82614038381045!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475a658536a76bc3%3A0x6dc319b0f419c14!2sSC%20Novi%20Beograd%2C%20SRC%2011%20April!5e0!3m2!1sen!2srs!4v1604785600200!5m2!1sen!2srs'
                        width='1000'
                        height='600'
                        frameBorder='0'
                        style={{ border: 0 }}
                        allowFullScreen={false}
                        aria-hidden='false'
                        tabIndex={0}
                    ></iframe>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default Contact;
