import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import ReactGA from 'react-ga';
import './i18n/i18next';
import { ErrorBoundary } from 'react-error-boundary'
import ScrollToTop from './utils/ScrollToTop'
import { HelmetProvider } from 'react-helmet-async';
import i18next from "i18next";
import Routes from './routes/RoutesAll'

ReactGA.initialize('UA-177416878-3');

export const ErrorFallback = ({ error }: any) => {
  return (
    <div className="bar error">
      <i className="ico">&#9747;</i> {error.message}
    </div>

  )
}

const myErrorHandler = (error: Error, info: { componentStack: string }) => {
  // Do something with the error
  // E.g. log to an error logging client here
  console.log(error)
  return (
    <div role="alert">
      <p style={{ color: 'blue' }} > Something went wrong:</p>
      <p>{error}</p>
    </div>
  )
}

function App() {

  const [isLoading, setIsLoading] = useState(true)
  const routesNew = Routes()

  useEffect(() => {
    const url = window.location.toString();
    const en = url.search("/en/");
    const sr = url.search("/sr/");

    if (en > 0) {
      i18next.changeLanguage("en");
      setIsLoading(false)
    } else if (sr > 0) {
      i18next.changeLanguage("sr");
      setIsLoading(false)
    }
    setIsLoading(false)
  }, []);

  return (<>
    <HelmetProvider>
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={myErrorHandler}>
        {!isLoading && <Router>
          < ScrollToTop />
          <Switch>
            {routesNew.map((props) => (
              <Route key={props.path} {...props} />
            ))}
          </Switch>
          < ScrollToTop />
        </Router >}
      </ErrorBoundary>
    </HelmetProvider>
  </>
  );
}

export default App;
