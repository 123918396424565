import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Helmet } from "react-helmet-async";
import { useTranslation, Trans } from "react-i18next";
// import Application from '../components/Aplication'
import { Link } from 'react-router-dom'
import i18next from "i18next";
import useWindowSize from '../utils/useWindowsSize'

const RecreationalProgram = () => {

    const { t } = useTranslation();
    const { width } = useWindowSize();

    return (<>
        <Helmet>
            <title>{`${t('Tipsarevic Tennis Academy | Rekreativni program')}`}</title>
        </Helmet>
        <Header cssClass={"section-three"} />
        <div className="wrapper-inner section-three">
            <div className="content intro">
                <h1>{t('Rekreativni tenis')}</h1>
                <p>{t('rekreacijaParagraf1')}</p>

            </div>
            <div className="quote" style={{
                backgroundImage: `url(/images/recreation-qoute.jpg)`,
            }}>
                <div className="quote-text">

                    {width > 1024 && <p className="message"><span style={{ color: '#378b8b' }}>„</span>{t('jankovaPorukaRekreacija')}<span style={{ color: '#378b8b' }}>“</span></p>
                    }
                    <p className="author"></p>
                </div>
            </div>
            <div className="content-wrapper triangle-photos recreational-day">
                <div className="content">
                    <h2><Trans i18nKey='rekreacijaTitle1'>Beogradska <br />
                teniska liga</Trans></h2>

                    <p className="full">{t('rekreacijaBTL1')}</p>
                    <p className="full">{t('rekreacijaBTL2')}</p>
                    <p className="full">{t('rekreacijaBTL3')}</p>
                    <p className="full">{t('rekreacijaBTL4')}</p>
                    <p className="full">{t('rekreacijaBTL5')}<a target="_blank" rel="noopener noreferrer" href='https://www.teniska-liga.rs'><u>www.teniska-liga.rs</u></a></p>

                </div>
            </div>
            {/*   <Application type={t("Rekreativni program")} isContact={false} /> */}
            <div className="other">
                <div className="other-program takmicarski">
                    <Link to={`/${i18next.language}/${t("takmicarskiprogram")}`}>
                        <h5>{t('Takmičarski program')}</h5>
                        <p>{t('skolaTenisaOpis3')}</p>
                    </Link>
                </div>
                <div className="other-program skola">
                    <Link to={`/${i18next.language}/${t("skolskiprogram")}`}>
                        <h5>{t('Škola tenisa')}</h5>
                        <p>{t('skolaTenisaOpis1')}</p>
                    </Link>
                </div>
            </div>
            <Footer />
        </div>

    </>
    )
}

export default RecreationalProgram
