import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from './lng/en'
import sr from './lng/sr'


i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            en: {
                translation: en
            },
            sr: {
                translation: sr
            },
                },
        lng: "en",
        fallbackLng: "en",

        interpolation: {
            escapeValue: false
        }
    });


