import React, { MouseEvent } from 'react'
import { useTranslation } from "react-i18next";
import { sendEmail } from '../services/Services'

interface Props {
    type: string,
    isContact: Boolean
}

const Aplication = ({ type, isContact }: Props) => {

    const { t } = useTranslation();
    const [formData, setFormData] = React.useState({
        userEmail: '',
        firstName: '',
        lastName: '',
        message: '',
        subject: type,
        phone: ''
    })

    const handleClick = (e: MouseEvent) => {
        e.preventDefault()
        if (!validateEmail(formData.userEmail)) return alert(t("Email je neispravan"))
        if (formData.firstName === '' || formData.lastName === '' || formData.message === '' || formData.userEmail === '') return alert(t("Molimo vas popunite polja ime, prezime, email i vaša poruka"))

        sendEmail(formData)
            .then(() => {
                setFormData({
                    userEmail: '',
                    firstName: '',
                    lastName: '',
                    message: '',
                    subject: type,
                    phone: ''
                })
                alert(t("Uspešno ste poslali email"))
            })
            .catch(error => {
                console.log(error)
                alert(error.message)
            })

        function validateEmail(email: string) {
            const re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
            return re.test(String(email).toLowerCase());
        }
    }

    return (
        <div className={isContact ? "contact-form content" : "application content"}>
            <div className="form">
                <h3>{isContact ? t('Pošaljite nam poruku') : t('Apliciraj za program')}</h3>
                <form action="">
                    <fieldset>
                        <input type="text" className="field-half mr" placeholder={t("Ime")} value={formData.firstName} onChange={(e) => setFormData({ ...formData, firstName: e.target.value })} />
                        <input type="text" className="field-half" placeholder={t("Prezime")} value={formData.lastName} onChange={(e) => setFormData({ ...formData, lastName: e.target.value })} />
                        <input type="text" className="field-half mr" placeholder={t("Telefon")} value={formData.phone} onChange={(e) => setFormData({ ...formData, phone: e.target.value })} />
                        <input type="text" className="field-half" placeholder="E-mail" value={formData.userEmail} onChange={(e) => setFormData({ ...formData, userEmail: e.target.value })} />
                    </fieldset>
                    <textarea name="" id="" cols={30} rows={10} placeholder={t("Vaša poruka")} value={formData.message} onChange={(e) => setFormData({ ...formData, message: e.target.value })} ></textarea>
                    <button onClick={handleClick}>{t('Pošalji')}</button>
                </form>
            </div>
        </div>
    )
}

export default Aplication
