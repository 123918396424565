import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Helmet } from "react-helmet-async";
import { useTranslation, Trans } from "react-i18next";
import Gallery from 'react-grid-gallery';
import { getGallery } from '../services/Services'
import useWindowSize from '../utils/useWindowsSize'
import i18next from "i18next";

const OurStory = () => {

    const { t } = useTranslation();
    const [images, setImages] = useState<object[]>()
    const [, setGalleryTitle] = useState({
        title: '',
        titleRS: ''
    })
    const { width } = useWindowSize();

    interface Image {
        url: string,
        title: string,
        titleRS: string
        formats: {
            small: {
                url: string,
                width: string,
                height: string
            }
        }
    }

    useEffect(() => {
        getGallery(4)
            .then(res => {
                res && res.data && setGalleryTitle({ title: res.data.title, titleRS: res.data.titleRS })
                const response = res && res.data && res.data.images.map((data: Image) => ({
                    src: `${process.env.REACT_APP_API}${data.url}`,
                    thumbnail: `${process.env.REACT_APP_API}${data.formats.small.url}`,
                    thumbnailWidth: data.formats.small.width,
                    thumbnailHeight: data.formats.small.height,
                    caption: ''
                }))
                setImages([...response])
            }
            )
            .catch(err => console.log(err))

    }, [])

    return (<>
        <Helmet>
            <title>{`${t('Tipsarevic Tennis Academy | Naša priča')}`}</title>
        </Helmet>
        <Header cssClass={"section-one"} />
        <div className="wrapper-inner section-one otherpages">
            <div className="content">
                <h1>{t('Naša priča')}</h1>
                <img className="fl-right" src={require('../images/nasa-prica-photo-7.jpg').default} alt="" />
                <p>{t('nasaPricaText')}</p>
                <p><Trans i18nKey="nasaPricaText1"> Zbog njegove velike zelje da ostvari taj cilj, Janko je okupio najbolje menadzere, trenere, i fitness strucnjake, sa kojima je saradjivao tokom svoje karijere, ne bi li oformio tim snova. Ovo je razlog zasto Tipsarevic Tennis Academy trenutno vazi za najbolju akademiju kako u Srbiji, tako i u regionu, nudeci najvisi moguci nivo usluge za sve sto je neophodno igracu koji se uvek nalazi u fazi razvoja.</Trans>
                </p>
                <p>{i18next.language === 'en' && t('nasaPricaText2')}</p>

            </div>

            {width > 800 && <div className="quote">
                <div className="quote-text">
                    <p className="message"><span>„</span>{t('jankovaPorukaNasaPrica')}<span style={{ color: 'white' }}>{width > 768 && <br />}{t('- Nastaviš Da Kopaš -')}</span><span>“</span></p>
                </div>
            </div>}

            {width < 800 && <div style={{ width: '90%', margin: '20px auto' }}>
                <div className="quote-text">
                    <p className="message" style={{ marginBottom: '30px' }}><span>„</span>{t('jankovaPorukaNasaPrica')}<span style={{ color: 'white' }}>{width > 768 && <br />}{t('- Nastaviš Da Kopaš -')}</span><span>“</span></p>
                </div>
                <img style={{ width: '100%', height: 'auto' }} src={require('../images/tipsarevic-quote-photo.png').default} alt="" />
            </div>}


            <div className="content">
                {/*     <div className="three-photos">
                    <div><img src={require('../images/nasa-prica-photo-2.jpg').default} alt="" /></div>
                    <div><img src={require('../images/nasa-prica-photo-3.jpg').default} alt="" /></div>
                    <div><img src={require('../images/nasa-prica-photo-4.jpg').default} alt="" /></div>
    </div><p></p> */}
                {/*<p>{t('nasaPricaSection1')}</p>
                <p>
                    {t('nasaPricaSection2')}</p>
                <p>
{t('nasaPricaSection3')}</p> */}

                {width > 767 && <div className="gallery content">
                    {/* <h6>{i18next.language === 'en' ? galleryTitle.title : galleryTitle.titleRS}</h6> */}
                    {images && <Gallery images={images} maxRows={1} enableImageSelection={false} backdropClosesModal={true} imageCountSeparator={t('od')} />}
                </div>}
                {/*   <p>
                    {t('nasaPricaSection4')}</p>
                <p>
             {t('nasaPricaSection5')}  </p>*/}
            </div>


            <Footer />
        </div>

    </>
    )
}

export default OurStory
