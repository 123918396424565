import React from 'react';

const Footer = () => {
    return (
        <div className='footer-inner'>
            <p className='copyrights'>
                All rights reserved by Tipsarevic tennis academy{' '}
                <a
                    target='_blank'
                    rel='noopener noreferrer'
                    href='https://www.swibs.xyz/'
                >
                    Powered by SWIBS
                </a>
            </p>
        </div>
    );
};

export default Footer;
