import React, { useState, useEffect } from 'react'
import Carousel from 'react-spring-3d-carousel';
import { config } from "react-spring";
import useWindowSize from '../utils/useWindowsSize'

const CarouselSlider = () => {

    const [slideTo, setSlideTo] = useState<number>(0)
    const [offsetRadius, setOffsetRadius] = useState<number>(1)
    const { width } = useWindowSize();

    useEffect(() => {
        if (width < 800) setOffsetRadius(1)
        else if (width < 1024) setOffsetRadius(1)
        if (width > 1024) setOffsetRadius(2)
    }, [width])

    useEffect(() => {
        let timer = setInterval(() => {
            setSlideTo(currentNum => {
                if (slides.length - 1 === currentNum) {
                    return 0
                }
                return currentNum + 1
            })
        }, 4000)
        return () => {
            clearInterval(timer)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slideTo])


    const slides: object[] = [
        {
            key: 0,
            content: <div style={width < 800 ? slideTo === 0 ? {} : {} : {}}>
                <img src="/images/playersSlider/Berankis_Ricardas-1.png" alt="Berankis Ricardas" />
                <span className={'carusellName'}>Berankis Ricardas</span>
            </div>
        },
        {
            key: 1,
            content: <div style={width < 800 ? slideTo === 1 ? {} : {} : {}}>
                <img src="/images/playersSlider/Danka_Kovinic-1.png" alt="Danka Kovinić" />
                <span className={'carusellName'}>Danka Kovinić</span>
            </div>

        },
        {
            key: 2,
            content: <div style={width < 800 ? slideTo === 2 ? {} : {} : {}}>
                <img src="/images/playersSlider/laslo_djere-1.png" alt="Laslo Djere" />
                <span className={'carusellName'}>Laslo Djere</span>
            </div>
        },
        {
            key: 3,
            content: <div style={width < 800 ? slideTo === 3 ? {} : {} : {}}>
                <img src="/images/playersSlider/Dusan_Lajovic-1.png" alt="Dušan Lajović" />
                <span className={'carusellName'}>Dušan Lajović</span>
            </div>
        },

        {
            key: 4,
            content: <div style={width < 800 ? slideTo === 4 ? {} : {} : {}}>
                <img src="/images/playersSlider/Kecmanovic_Milan-1.png" alt="Miomir Kecmanović" />
                <span className={'carusellName'}>Miomir Kecmanović</span>
            </div>

        }, {
            key: 5,
            content: <div style={width < 800 ? slideTo === 5 ? {} : {} : {}}>
                <img src="/images/playersSlider/Mate_Pavic-1.png" alt="Mate Pavić" />
                <span className={'carusellName'}>Mate Pavić</span>
            </div>
        },
        {
            key: 6,
            content: <div style={width < 800 ? slideTo === 6 ? {} : {} : {}}>
                <img src="/images/playersSlider/Harriet_Dart-1.png" alt="Harriet Dart" />
                <span className={'carusellName'}>Harriet Dart</span>
            </div>
        },
        {
            key: 7,
            content: <div style={width < 800 ? slideTo === 7 ? {} : {} : {}}>
                <img src="/images/playersSlider/Yuichi_Sugita-1.png" alt="Yuichi Sugita" />
                <span className={'carusellName'}>Yuichi Sugita</span>
            </div>
        },
    ].map((slide, index) => {
        return {
            ...slide, onClick: () => {
                setSlideTo(index)
            }
        };
    })

    return (<>
        <div className="carusel-holder">
            {width < 800 && <div className="prev" style={{ opacity: 0 }} onClick={() => setSlideTo(currentNum => {
                if (currentNum === 0) return slides.length - 1
                else return currentNum - 1
            })
            }>Prev</div>
            }
            <Carousel
                slides={slides}
                goToSlide={slideTo}
                showNavigation={false}
                offsetRadius={offsetRadius}
                animationConfig={config.gentle} />
            {width < 800 && <div className="next" style={{ opacity: 0 }} onClick={() => setSlideTo(currentNum => {
                if (slides.length - 1 === currentNum) return 0
                else return currentNum + 1
            })}> Next</div>
            }

        </div>
    </>
    )
}


export default CarouselSlider
