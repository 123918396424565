import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Helmet } from "react-helmet-async";
import { Trans, useTranslation } from "react-i18next";
import Application from '../components/Aplication'
import { Link } from 'react-router-dom'
import i18next from "i18next";
import useWindowSize from '../utils/useWindowsSize'

const SchoolPrograms = () => {

    const { t } = useTranslation();
    const { width } = useWindowSize();

    return (<>
        <Helmet>
            <title>{`${t('Tipsarevic Tennis Academy | Školski program')}`}</title>
            < meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0" />
        </Helmet>
        <Header cssClass={"section-two"} />
        <div className="wrapper-inner section-two">
            <div className="content intro">
                <h1>{t('Škola tenisa')}</h1>
                <img className="fl-right" src={require('../images/skolskiprogram.jpg').default} alt="" />
                <p>{t('skolatenisaPragraf1')}</p>
                <p>{t('skolatenisaPragraf2')}</p>
                <p>{t('skolatenisaPragraf3')}</p>
                <p>{t('skolatenisaPragraf4')}</p>
                <Trans i18nKey="skolatenisaPragraf5">
                    <p>KVALITET rada garantuju:<br /></p>
                </Trans>
                {width < 1024 ?
                    <div className="work-quality">
                        <ul style={{ listStyle: 'inside disc' }}>
                            <li style={{ listStyle: 'inside disc', float: 'none' }} >{t('skolaTenisaLista1')}</li>
                            <li style={{ listStyle: 'inside disc', float: 'none' }}>{t('skolaTenisaLista2')}</li>
                            <li style={{ listStyle: 'inside disc', float: 'none' }}>{t('skolaTenisaLista3')}</li>
                            <li style={{ listStyle: 'inside disc', float: 'none' }}>{t('skolaTenisaLista5')}</li>
                            <li style={{ listStyle: 'inside disc', float: 'none' }}>{t('skolaTenisaLista6')}</li>
                            <li style={{ listStyle: 'inside disc', float: 'none' }}>{t('skolaTenisaLista4')}</li>
                        </ul>
                    </div>
                    :
                    <div className="work-quality">
                        <ul style={{ listStyle: 'inside disc' }}>
                            <li style={{ listStyle: 'inside disc', float: 'none' }} >{t('skolaTenisaLista1')}</li>
                            <li style={{ listStyle: 'inside disc', float: 'none' }}>{t('skolaTenisaLista2')}</li>
                            <li style={{ listStyle: 'inside disc', float: 'none' }}>{t('skolaTenisaLista3')}</li>

                        </ul>
                        <ul>
                            <li style={{ listStyle: 'inside disc', float: 'none' }}>{t('skolaTenisaLista5')}</li>
                            <li style={{ listStyle: 'inside disc', float: 'none' }}>{t('skolaTenisaLista6')}</li>
                            <li style={{ listStyle: 'inside disc', float: 'none' }}>{t('skolaTenisaLista4')}</li>
                        </ul>

                    </div>}

            </div>


            {width > 800 && <div className="quote" style={{
                backgroundImage: `url(/images/school-quote.jpg)`,
            }}>
                <div className="quote-text">
                    {width > 1024 && <p className="message"><span>„</span>{t('porukaSkolaTenisa')}<span>“</span></p>}
                    <p className="author"></p>
                </div>
            </div>}

            {width < 800 && <div style={{ width: '90%', margin: '20px auto' }}>
                <div className="quote-text">
                    {width > 1024 && <p className="message"><span>„</span>{t('porukaSkolaTenisa')}<span>“</span></p>}
                </div>
                <img style={{ width: '100%', height: 'auto' }} src={require('../images/school-quote.jpg').default} alt="" />
            </div>}



            <div className="content-wrapper triangle-photos school-day">
                <div className="content">
                    <h2>{t('Programi')}</h2>
                    <Trans i18nKey="skolatenisaPragraf6">
                        <p>Naši programi su usklađeni za sve uzraste:<br /></p>
                    </Trans>
                    <p>
                        <ul style={{ listStyle: 'disc inside' }}>
                            <li style={{ listStyle: 'disc inside', float: 'none' }} >{t('skolaTenisaLista8')}</li>
                            <li style={{ listStyle: 'disc inside', float: 'none' }}>{t('skolaTenisaLista9')}</li>
                            <li style={{ listStyle: 'disc inside', float: 'none' }}>{t('skolaTenisaLista10')}</li>
                        </ul>
                    </p>

                    {/* <p className="full">{t('skolatenisaPragraf8')}</p> */}
                    <p>{t('skolatenisaPragraf7')}</p>

                </div>
            </div>

            <Application isContact={false} type={t("Školski program")} />
            <div className="other">
                <div className="other-program takmicarski">
                    <Link to={`/${i18next.language}/${t("takmicarskiprogram")}`}>
                        <h5>{t('Takmičarski program')}</h5>
                        <p>{t('skolaTenisaOpis3')}</p>
                    </Link>
                </div>
                <div className="other-program rekreativa">
                    <Link to={`/${i18next.language}/${t("rekreativniprogram")}`}>
                        <h5>{t('Rekreativni tenis')}</h5>
                        <p>{t('skolaTenisaOpis2')}</p>
                    </Link>
                </div>
            </div>
            <Footer />
        </div>

    </>
    )
}

export default SchoolPrograms
