const sr = {


    'takProgram': 'Takmičarski <br /> program',
    'napredniProgram': 'Napredni programi Teniske akademije Tipsarević',
    'skolskiProgramTitle': 'Kvalitetan rad na svim nivoima bavljenja tenisom',
    'rekreativniProgramTitle': 'Rezervišite terene + uključite se u ligu',
    'Saznaj više': 'Saznaj više',
    'Programi': 'Programi',
    'Takmičarski program': 'Takmičarski program',
    'Škola tenisa': 'Škola tenisa',
    'Školski program': 'Školski program',
    'Rekreativni tenis': 'Rekreativni tenis',
    'Akademija': 'Akademija',
    'Naša priča': 'Naša priča',
    'Igrači': 'Igrači',
    'Tim': 'Tim',
    'Informacije': 'Informacije',
    'Vesti': 'Vesti',
    'Galerije': 'Galerije',
    'Kontakt': 'Kontakt',
    'Lokacije': 'Lokacije',
    'Olimp': 'Olimp',
    'kontaktAdresaTelefonOlimp': 'Vjekoslava Kovača 11,<br /> 11000 Beograd <br /> +381 (11) 7357679',
    'kontaktAdresaTelefonSC': 'Autoput za Zagreb 2, <br /> 11000 Beograd <br /> +381 (64) 1191272',
    'staNudimo1': 'Tenis je sport koji zahteva brz način života, u kome su jedina konstanta promene i novi izazovi, a igrač često prepušten sebi mora da nalazi rešenja za iste.',
    'staNudimo2': 'Naš program akademije je osmišljen da prati, kroz video analizu i druge metode prikupljanja informacija, svaki aspekt tih promena koje se dešavaju u karijeri jednog igrača. Ovo je jedan od razloga zašto je svaki trenažni dan u akademiji organizovan na najprofesionalniji mogući nacin, ne bi li se što preciznije prikupile informacije koje se kasnije tog istog dana analiziraju unutar tima.',
    'staNudimo3': 'Svaki od naših trenera je zadužen za tri do četiri igraca, i svake nedelje se prave izveštaji o tim igračima koji se diskutuju sa našim glavnim trenerima Mićom Ratkovićem i Jankom Tipsarevićem. Svi nedeljni izveštaji se prikupljaju ne bi li na kraju meseca bili dostupni kako igračima tako i roditeljima. Naš tim koji je ujedno i srce naše akademije je posvećen da prati svaki detalj igračevog progresa, i samim tim mi smo u konstantnom traganju kako možemo da pratimo tehnički, fizički, i mentalni napredak bez da nam išta promakne. Od smeštaja, ishrane, fitnesa, transporta, i planiranja turnira, mi smo osmislili takav sistem rada da naši igrači ne moraju da brinu ni o čemu drugome sem o svom progresu i da svu energiju usmere u isti. ',
    'Programi su podeljeni na': 'Programi su podeljeni na:',
    'Nedeljni': 'Nedeljni',
    'Mesečni': 'Mesečni',
    'Godišnji': 'Godišnji',
    'Preuzmi brošuru': 'Preuzmi brošuru',
    'jankovaPorukaNasaPrica': 'Moj životni moto i moto akademije je da uvek nadjes način da',
    '- Nastaviš Da Kopaš -': ' -Nastaviš Da Kopaš-',
    'jankovaPorukaTakmicari': 'Naša akademija teži da stvori ekosistem u kojem se pazi na svaki detalj.',
    'danAkademija': 'Jedan dan <br /> u Tipsarević <br /> akademiji',
    'oProgramima': 'U našoj ponudi se nalaze: nedeljni, mesecni, i godišnji program (koji nudi bonus organizaciju deset nedelja turnira u pratnji trenera), a na vama je da izaberete šta je najbolja opcija za vas. Naš konačni cilj je kvalitet i sigurni smo da kad iskusite nivo istoga, koji nudimo ostaćete koliko god budete spremni da se posvetite radu na sebi i sopstvenom progresu.',
    'Apliciraj za program': 'Apliciraj za program',
    'Kontakt forma': 'Kontakt forma',
    "Email je neispravan": "Email je neispravan.",
    "Molimo vas popunite polja ime, prezime, email i vaša poruka": "Molimo vas popunite polja ime, prezime, email i vaša poruka",
    'Ime': 'Ime',
    "Uspešno ste poslali email": "Uspešno ste poslali email.",
    'Prezime': 'Prezime',
    'Telefon': 'Telefon',
    'Pošalji': 'Pošalji',
    'od': ' od ',
    'skolaTenisaOpis1': 'Kvalitetan rad na svim nivoima bavljenja tenisom',
    'skolaTenisaOpis2': 'Rezervišite terene + uključite se u ligu',
    'skolaTenisaOpis3': 'Napredni programi Teniske akademije Tipsarević',
    'Tipsarevic Tennis Academy | Rekreativni program': 'Tipsarević Tennis Academy | Rekreativni program',
    'Tipsarevic Tennis Academy | Školski program': 'Tipsarević Tennis Academy | Školski program',
    'Tipsarevic Tennis Academy | Takmičarski program': 'Tipsarević Tennis Academy | Takmičarski program',
    'Tipsarevic Tennis Academy | Igrači': 'Tipsarević Tennis Academy | Igrači',
    'Tipsarevic Tennis Academy | Tim': 'Tipsarević Tennis Academy | Tim',
    'Tipsarevic Tennis Academy | Vesti': 'Tipsarević Tennis Academy | Vesti',
    'Tipsarevic Tennis Academy | Galerije': 'Tipsarević Tennis Academy | Galerije',
    'Vaša poruka': 'Vaša poruka',
    'Rekreativni program': 'Rekreativni program',
    'skolskiProgram': 'Škola <br /> tenisa',
    'skolskiProgram1': 'Naši programi za mlade nude kvalitetan <br /> rad na svim nivoima bavljenja tenisom',
    'rekreativniProgram': 'Rekreativni <br /> Tenis',
    'rekreativniProgram1': 'Čini nam veliko zadovoljstvo da ljubav prema našem sportu <br /> podelimo i sa rekreativcima koji ga istinski vole!',
    'Pošaljite nam poruku': 'Pošaljite nam poruku',
    'GdeSeNalazimo': `Nalazimo se u Beogradu na dve lokacije – prvi teniski centar pod patronatom ove Akademije je u neposrednoj blizini sportskog centra "11. april" (tri terena), drugi „Tipsarević – Olimp“ sa šest terena od šljake i tri terena sa tvrdom podlogom u odličnom ambijentu za razvoj mladih teniskih igrača, smešten je u okviru SC Olimp na Zvezdari.`,
    'NAŠA GLAVNA LOKACIJA JE U SKLOPU SC "OLIMP-ZVEZDARA': 'GLAVNA LOKACIJA',
    'Prikaži mapu ove lokacije': 'Prikaži mapu ove lokacije',
    'Tipsarevic Tennis Academy | Naša priča': 'Tipsarević Tennis Academy | Naša priča',
    'Tipsarevic Tennis Academy | Kontakt': 'Tipsarević Tennis Academy | Kontakt',
    'nasaPricaText': '“Teniska Akademija Tipsarević” je osnovana 2013. godine od strane Janka Tipsarevića u njegovom rodnom gradu - Beogradu. Jankov glavni cilj bio je da prenese svoje bogato iskustvo i znanje mladim teniserima u nadi da će im pomoći da postanu što bolji igrači, a zatim i što bolji ljudi.',
    'nasaPricaText1': 'Zbog njegove velike želje da ostvari taj cilj, Janko je okupio najbolje menadžere, trenere, i fitness stručnjake, sa kojima je saradjivao tokom svoje karijere, ne bi li oformio tim snova. Ovo je razlog zašto “Teniska Akademija Tipsarević” trenutno važi za najbolju akademiju kako u Srbiji, tako i u regionu, nudeći najviši moguci nivo usluge za sve sto je neophodno igraču koji se uvek nalazi u fazi razvoja.',
    'nasaPricaSection1': 'To je jedna od najvažnijih teniskih akademija u Srbiji i posvećena je obrazovanju tenisera u svim aspektima ovog sporta.',
    'nasaPricaSection2': 'Pored osnivača, glavnog trenera i menadžera Janka, stručni kadar Akademije broji još 15 trenera, koji rade sa desetinama registrovanih takmičara u okviru teniskih škola kao i većim brojem već formiranih igrača u svom “high performance” programu.',
    'nasaPricaSection3': 'Naš tim kreira kvalitetan program koji će dovesti mlade tenisere do gornje granice svojih sposobnosti kada su u pitanju igrački, psihički i fizički potencijal s kojim raspolažu.',
    'nasaPricaSection4': 'Pored takmičarskih programa ponosimo se i velikim brojem naših rekreativaca svakodnevno prisutnim u našim centrima za koje smo omogućili igranje Rekreativne lige tokom zime u centru na Zvezdari.',
    'nasaPricaSection5': 'Ukoliko volite tenis, ukoliko želite da napravite prve teniske korake, ili samo želite da postanete šampion, pridružite nam se i budite deo našeg tima.',
    'Učitaj više vesti': 'Učitaj više vesti',
    'Srbija': 'Srbija',
    'Rusija': 'Rusija',
    'Japan': 'Japan',
    'Litvanija': 'Litvanija',
    'Bugarska': 'Bugarska',
    'Engleska': 'Engleska',
    'takmicarskiprogram': 'takmicarskiprogram',
    'rekreativniprogram': 'rekreativniprogram',
    'skolskiprogram': 'skolskiprogram',
    'nasaprica': 'nasaprica',
    'kontakt': 'kontakt',
    'igraci': 'igraci',
    'tim': 'tim',
    'vesti': 'vesti',
    'galerije': 'galerije',
    'igraciText': 'Kada ne putuju po svetu takmičeći se za trofeje ovi igraci se pripremaju sa nama. Pored šest terena na sljaci i tri “US OPEN” betonska terena, igrači na svom raspolaganju imaju i pristup stazi za trčanje, teretanu (unutra i napolju), restoran sa vrhunskom hranom, sjajne fizioterapeute i sve to bez da ikada moraju da napuste naš kompleks.',
    'Godina': 'Godina',
    'Najbolji': 'Najbolji',
    'rang': 'rang',
    'skolatenisaPragraf1': 'Naš tim poklanja posebnu pažnju organizaciji škole tenisa, jer smo svesni da budućnost tenisa pripada mladima i da će oni naslediti ono što naše trofejne generacije ostavljaju za sobom. Zbog toga naš cilj je da omogućimo velikom broju dece da se pravilno razvijaju kroz ovaj lepi sport i kvalitetnim radom ostvare svoje potencijale i pomere lične granice. Naša želja je da im pomognemo da budu ponosni na ono što rade i poštovani zbog načina na koji to rade.',
    'skolatenisaPragraf2': '',
    'skolatenisaPragraf3': 'Osnovna dva stuba programa za decu su PRISTUPAČNOST i KVALITET.',
    'skolatenisaPragraf4': 'Na akademiji se trudimo da omogućimo velikom broju dece da se pravilno razvija kroz sport, putem raznih oblika kombinovanog rada i selektiranja, dok se oni uz rad lepo druže. Zato se trudimo da ovi programi budu PRISTUPAČNI.',
    'skolatenisaPragraf5': '<p>KVALITET rada garantuju:<br /></p>',
    'skolaTenisaLista1': 'INTENZIVNOST programa (male grupe)',
    'skolaTenisaLista2': 'ISKUSTVO trenera',
    'skolaTenisaLista3': 'INDIVIDUALNI RAD',
    'skolaTenisaLista4': 'FITNES programi',
    'skolaTenisaLista5': 'USLOVI ZA RAD koji su vrhunski',
    'skolaTenisaLista6': 'VANTRENAŽNE klupske aktivnosti',
    'skolaTenisaLista7': 'PODRŠKA akademije.',
    'skolatenisaPragraf6': '<p>Naši programi su usklađeni za sve uzraste:<br /></p>',
    'skolaTenisaLista8': '10s tenis za decu 4-10 godina (crveni, narandžasti i zeleni nivo)',
    'skolaTenisaLista9': 'programi za decu do 12god, 14god i 16god.',
    'skolatenisaPragraf7': 'Nas dugoročni cilj je da pratimo progres dece u školi tenisa sve dok ne budu spremni za takmičarski program, zbog čega smo uspostavili prirodan prelazak iz jednog programa u drugi, koji naši treneri nadgledaju i vode. ',
    'skolatenisaPragraf8': 'Trenutno u našem programu na 11. Aprilu se nalazi 200+ dece. ' +
        'U 2020. Tipsarević Tennis Academy je uspostavio saradnju sa Happy Kids vrtićima. ' +
        'U 2021. Skola tenisa se proširuje i na našu Olimp lokaciju.',
    'skolatenisaPragraf9': 'Svi u Akademiji se slažemo da je naša glavna snaga u ljubavi i strasti prema ovom sportu, velikom iskustvu i tradiciji, kao i stalnoj unutrasnjoj zelji ka unapređivanju. Jankova postignuća u svetu tenisa su temelj na kojem se razvijamo. Iskustva koja svakodnevno stiču svi naši igrači, treneri i ljudi iz organizacije su koraci kojima se svakodnevno unapređujemo. ',
    'nasTimText1': 'Naša akademija jeste naš tim. Pored najboljih teniskih trenera na svetu, mi takodje nudimo i usluge za svaki deo igračevog zivota. Od fitnes trenera, menadžera, i fizioterapeuta mi nastojimo da pomognemo igračima gde god je to potrebno.',
    'nasTimText2': 'Upoznajte naš tim:',
    'porukaSkolaTenisa': 'Dođite, da sve što znamo podelimo sa vama.',
    'Nazad na vesti': 'Nazad na vesti',
    'Ostale vesti': 'Ostale vesti',
    'Nazad na galerije': 'Nazad na galerije',
    'LOKACIJA NA KOJOJ VODIMO SVOJU TENISKU ŠKOLU': 'DRUGA LOKACIJA',
    'rekreacijaParagraf1': 'Svaki dan nakon 17h naša akademija prestaje sa takmičarskim programima a na scenu stupaju naši rekreativci. Uz zalazak sunca, a potom i uz reflektore tenis se odvija u svom najčistijem obliku do kasnih sati u noc. Pored opcije da iznajmite terene i podelite termin sa svojim prijateljima, mozete se prikljuciti i Beogradskoj Teniskoj Ligi.',
    'rekreacijaTitle1': 'BEOGRADSKA <br /> TENISKA LIGA',
    'rekreacijaBTL1': 'Beogradska Teniska Liga (BTL) je jedno od najvećih amaterskih udruženja ljubitelja tenisa koja u 3 Beogradska teniska kluba okuplja više od 150 amatera koji dele svoju strast za ovom plemenitom igrom.',
    'rekreacijaBTL2': 'Iako nije u pitanju profitna organizacija, ili neko aktivno sportsko društvo, ova liga je organizovana na veoma visokom nivou, često bolje i od nekih profesionalnih sportskih udruženja i organizacija. U tome joj svakako pomaže digitalizacija i automatizacija implementirana u izradu nedeljnih rasporeda mečeva čiji se rezultati promtno listiraju i postaju vidljivi na internet stranici, kao i Android i IOS aplikaciji. Zahvaljujući svemu ovome, kao i iskrenom entuzijazmu i neverovatnoj upornosti, Beogradska Teniska Liga postala je veoma važan faktor amaterskog tenisa u Srbiji.',
    'rekreacijaBTL3': 'Beogradska Teniska Liga održava se i na terenima TK Olimp gde se ujedno održava i Teniska liga dublova. Novi BTL format takmičenja nastao je sa ciljem promovisanja teniskih dublova kao idealnog načina rekreacije svih zaljubljenika u tenis bez obzira na godine i pol.',
    'rekreacijaBTL4': 'Ono što Beogradsku Tenisku Ligu izdvaja jeste i organizacija MASTERS KUPOVA na kraju svake sezone gde se najbolje plasirani teniseri iz sva tri kluba bore za laskavu MASTERS KUP titulu. Tako se dva puta godišnje na terenima jednog od klubova na kojima se inače igraju mečevi BTL lige organizuje završni turnir na kome je osim na zaista kvalitetnom tenisu, akcenat na druženju svih BTL ligaša.',
    'rekreacijaBTL5': 'Više informacija na ',
    'jankovaPorukaRekreacija': 'Nastavi da igraš.',
    'skolaTenisaLista10': 'Polaznici vrtića "Happy Kids"',
    'recreationaltennis': 'rekreativniprogram',
    'highperformance': 'takmicarskiprogram',
    'youthtennis': 'skolskiprogram',
    'ourstory': 'nasaprica',
    'players': 'igraci',
    'team': 'tim',
    'news': 'vesti',
    'galleries': 'galerije',
    'contact': 'kontakt',
    'translateCookies': 'Ova web lokacija koristi kolačiće za poboljšanje korisničkog iskustva.',
    'dugmeSlazem': 'Slažem se',
    'Teniski Trener': 'Teniski Trener',
    'Direktor': 'Direktor',
    'Glavni Trener': 'Glavni Trener',
    'Direktor Tenisa': 'Direktor Tenisa',
    'Osnivač': 'Osnivač',
    'Fitnes Trener': 'Fitnes Trener',
    'Consultant': 'Konsultant',
    'Generalni Menadžer': 'Generalni Menadžer',
    'Fizioterapeut': 'Fizioterapeut',
}

export default sr