import React, { MouseEvent } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet-async';
import { useTranslation, Trans } from 'react-i18next';
import Aplication from '../components/Aplication';
import { Link } from 'react-router-dom';
import { saveAs } from 'file-saver';
import i18next from 'i18next';

const AcademyPrograms = () => {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>{`${t(
                    'Tipsarevic Tennis Academy | Takmičarski program',
                )}`}</title>
                <meta
                    name='viewport'
                    content='width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0'
                />
            </Helmet>
            <Header cssClass={'section-one'} />
            <div className='wrapper-inner section-one'>
                <div className='content intro'>
                    <h1>{t('Takmičarski program')}</h1>

                    <img
                        className='fl-right'
                        src={require('../images/janko-pobeda.jpg').default}
                        alt=''
                    />
                    <p>{t('staNudimo1')}</p>
                    <p>{t('staNudimo2')}</p>
                    <p>{t('staNudimo3')}</p>
                </div>

                <div
                    style={{
                        width: '100%',
                        maxWidth: '1280px',
                        margin: 'auto',
                        marginTop: '80px',
                    }}
                >
                    <video
                        controls
                        className='w-full h-full object-cover'
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                        }}
                        preload="metadata"
                    >
                        <source
                            src='https://www.tipsarevictennisacademy.com/api/uploads/0-02-05-0c7f3a0f89ffe387ab8566d91946733f766f17cc01e17ef41f8179fdc354c888_b678692e1f71406b_2.mp4#t=0.001'
                            type='video/mp4'
                        />
                    </video>
                </div>

                <div
                    className='quote'
                    style={{
                        backgroundImage: `url(/images/hignperformance-quote.jpg)`,
                    }}
                >
                    <div className='quote-text'>
                        <p className='message'>
                            <span>„</span>
                            {t('jankovaPorukaTakmicari')}
                            <span>“</span>
                        </p>
                        <p className='author'></p>
                    </div>
                </div>
                <div className='content-wrapper academy-day'>
                    <div className='content intro'>
                        <h2>
                            <Trans>Programi</Trans>
                        </h2>
                        <p className='full'>{t('oProgramima')}</p>
                        <ul>
                            <li>
                                <span>{t('Nedeljni')}</span>
                                <Link
                                    to=''
                                    className='ned'
                                    onClick={(e: MouseEvent) => {
                                        e.preventDefault();
                                        saveAs(
                                            '../3 TTA Weekly Program 2021.pdf',
                                            '3 TTA Weekly Program 2021.pdf',
                                        );
                                    }}
                                >
                                    {t('Preuzmi brošuru')}
                                    <span></span>
                                </Link>
                            </li>
                            <li>
                                <span>{t('Mesečni')}</span>
                                <a
                                    className='mes'
                                    href='#/'
                                    onClick={(e: MouseEvent) => {
                                        e.preventDefault();
                                        saveAs(
                                            '../2 TTA Monthly Program 2021.pdf',
                                            '2 TTA Monthly Program 2021.pdf',
                                        );
                                    }}
                                >
                                    {t('Preuzmi brošuru')}
                                    <span></span>
                                </a>
                            </li>
                            <li>
                                <span>{t('Godišnji')}</span>
                                <a
                                    className='god'
                                    href='#/'
                                    onClick={(e: MouseEvent) => {
                                        e.preventDefault();
                                        saveAs(
                                            '../1 TTA Annual Program 2021.pdf',
                                            '1 TTA Annual Program 2021.pdf',
                                        );
                                    }}
                                >
                                    {t('Preuzmi brošuru')}
                                    <span></span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <Aplication isContact={false} type={t('Takmičarski program')} />
                <div className='other'>
                    <div className='other-program skola'>
                        <Link
                            to={`/${i18next.language}/${t('skolskiprogram')}`}
                        >
                            <h5>{t('Škola tenisa')}</h5>
                            <p>{t('skolaTenisaOpis1')}</p>
                        </Link>
                    </div>
                    <div className='other-program rekreativa'>
                        <Link
                            to={`/${i18next.language}/${t(
                                'rekreativniprogram',
                            )}`}
                        >
                            <h5>{t('Rekreativni tenis')}</h5>
                            <p>{t('skolaTenisaOpis2')}</p>
                        </Link>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default AcademyPrograms;
