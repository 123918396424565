/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, Fragment, useEffect, MouseEvent } from 'react'
import NavBar from './NavBar'
import i18next from "i18next";
import { Link } from "react-router-dom"
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { disableBodyScroll, BodyScrollOptions, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface Props {
    cssClass?: string
}

const Header = ({ cssClass }: Props) => {
    const [isOpen, setIsOpen] = useState<Boolean>(false)
    const [activeLang, setActiveLang] = useState<String>(i18next.language)
    const [hideOnScroll, setHideOnScroll] = useState(true)
    const root = document.body as HTMLDivElement /*todo get wrapper element*/
    let history = useHistory();
    let location = useLocation();
    const { t } = useTranslation();

    const options: BodyScrollOptions = {
        reserveScrollBarGap: true,
    };

    useScrollPosition(({ prevPos, currPos }) => {
        if (currPos.y > -50) return setHideOnScroll(true)
        else setHideOnScroll(false)
    },
        [hideOnScroll]
    )
    useEffect(() => isOpen === true ? disableBodyScroll(root, options) : clearAllBodyScrollLocks(),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isOpen]
    );

    return (<Fragment>
        <header className={hideOnScroll ? cssClass : "sticky " + cssClass}>
            <div className="header-left">
                <Link to="/#" className={"menu-icon " + (isOpen ? "open" : "")} onClick={(e: MouseEvent) => {
                    e.preventDefault()
                    setIsOpen(!isOpen)
                }}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </Link>
                <NavBar activeLang={activeLang} setActiveLang={setActiveLang} cssClass={isOpen ? 'open-nav' : ''} />
            </div>
            <Link to="/" className="logo"></Link>
            <div className="header-right">
                <ul className="social">
                    <li className="facebook"><a target="_blank" rel="noopener noreferrer"
                        href="https://www.facebook.com/tipsarevictennisacademy/">Facebook</a></li>
                    <li className="instagram"><a target="_blank" rel="noopener noreferrer"
                        href="https://www.instagram.com/tipsarevictennisacademy">Instagram</a>
                    </li>
                    {/*<li className="youtube"><a target="_blank" rel="noopener noreferrer" href="http://www.tipsarevicacademy.com/#0">Youtube</a></li>*/}
                </ul>
                <div className="language">
                    <a href="" className={activeLang === 'en' ? "active" : ""} onClick={(e: MouseEvent) => {
                        i18next.changeLanguage('en')
                        setActiveLang('en')
                        const url = location.pathname.toString()

                        if (url.charAt(1) === 'e') return

                        if (url === '/') return e.preventDefault()
                        else {
                            const urlNew = url.slice(4)
                            return history.push('/en/' + t(urlNew))
                        }
                    }}>Eng</a>
                    <a href="" className={activeLang === 'sr' ? "active" : ""} onClick={(e: MouseEvent) => {
                        i18next.changeLanguage('sr')
                        setActiveLang('sr')

                        const url = location.pathname.toString()
                        if (url === '/') return e.preventDefault()
                        else {
                            const urlNew = url.slice(4)
                            return history.push('/sr/' + t(urlNew))
                        }
                    }}>Srb</a>
                    <ul>
                        <li>Srpski</li>
                        <li>English</li>
                    </ul>
                </div>

            </div>
        </header>

    </Fragment>
    )
}

export default Header
