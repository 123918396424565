import i18next from "i18next";
import WithTracker from '../utils/WithTracker'
import Homepage from '../pages/Homepage'
import AcademyPrograms from '../pages/AcademyPrograms'
import RecreationalProgram from '../pages/RecreationalPrograms'
import SchoolProgram from '../pages/SchoolProgram'
import OurStory from '../pages/OurStory'
import Contact from '../pages/Contact'
import NotFound from '../pages/NotFound'
import Players from '../pages/Players'
import Coaches from '../pages/Coaches'
import News from '../pages/News'
import NewsItem from '../pages/NewsItem'
import Galleries from '../pages/Galleries'
import Gallery from '../pages/Gallery'
import { useTranslation } from "react-i18next";

export default function Routes() {
    const { t } = useTranslation();

    const routes = [
        {
          exact: true,
          path: `/`,
          component: WithTracker(Homepage)
        },
        {
          exact: true,
          path: `/${i18next.language}/${t("takmicarskiprogram")}`,
          component: WithTracker(AcademyPrograms)
        },
        {
          exact: true,
          path: `/${i18next.language}/${t("rekreativniprogram")}`,
          component: WithTracker(RecreationalProgram)
        },
        {
          exact: true,
          path: `/${i18next.language}/${t("skolskiprogram")}`,
          component: WithTracker(SchoolProgram)
        },
        {
          exact: true,
          path: `/${i18next.language}/${t("nasaprica")}`,
          component: WithTracker(OurStory)
        },
        {
          exact: true,
          path: `/${i18next.language}/${t("kontakt")}`,
          component: WithTracker(Contact)
        },
        {
          exact: true,
          path: `/${i18next.language}/${t("igraci")}`,
          component: WithTracker(Players)
        },
        {
          exact: true,
          path: `/${i18next.language}/${t("tim")}`,
          component: WithTracker(Coaches)
        },
        {
          exact: true,
          path: `/${i18next.language}/${t("vesti")}`,
          component: WithTracker(News)
        },
        {
          exact: true,
          path: `/${i18next.language}/${t("vesti")}/:id`,
          component: WithTracker(NewsItem)
        },
        {
          exact: true,
          path: `/${i18next.language}/${t("galerije")}`,
          component: WithTracker(Galleries)
        },
        {
          exact: true,
          path: `/${i18next.language}/${t("galerije")}/:id`,
          component: WithTracker(Gallery)
        },
        {
          exact: false,
          path: '*',
          component: WithTracker(NotFound)
        }];

   return routes
}

