const en = {
    'takProgram': 'Competition <br /> program',
    'napredniProgram': 'Advanced programs of the TTA',
    'skolskiProgramTitle': 'Quality work at all levels',
    'rekreativniProgramTitle': 'Book a court + join a league',
    'kontaktAdresaTelefonOlimp': 'Vjekoslava Kovača 11,<br /> 11000 Belgrade <br /> +381 (11) 7357679',
    'kontaktAdresaTelefonSC': 'Autoput za Zagreb 2, <br /> 11000 Belgrade <br /> +381 (64) 1191272',
    'Ime': 'First name',
    'Tipsarevic Tennis Academy': 'Tipsarevic Tennis Academy',
    'Tipsarevic Tennis Academy - Programs': 'Tipsarevic Tennis Academy - Programs',
    'od': ' from ',
    'takmicarskiprogram': 'highperformance',
    'rekreativniprogram': 'recreationaltennis',
    'skolskiprogram': 'youthtennis',
    'Takmičarski program': 'High Performance',
    'nasaprica': 'ourstory',
    'kontakt': 'contact',
    'igraci': 'players',
    'Teniski Trener': 'Tennis Coach',
    'tim': 'team',
    'Tim': 'Team',
    'vesti': 'news',
    'galerije': 'galleries',
    'Saznaj više': 'Find out more',
    'Programi': 'Programs',
    'Škola tenisa': 'Youth Tennis',
    'Školski program': 'Youth program',
    'Rekreativni tenis': 'Recreational Tennis',
    'Fizioterapeut': 'Physiotherapist',
    'Generalni Menadžer': 'General Manager',
    'Konsultant': 'Consultant',
    'Akademija': 'Academy',
    'Naša priča': 'Our Story',
    'Direktor': 'Director',
    'Glavni Trener': 'Head Coach',
    'Direktor Tenisa': 'Tennis Director',
    'Osnivač': 'Founder',
    'Fitnes Trener': 'Fitness Coach',
    'Igrači': 'Players',
    'Team': 'Team',
    'Informacije': 'Informations',
    'Vesti': 'News',
    'Galerije': 'Gallery',
    'Kontakt': 'Contact',
    'Lokacije': 'Locations',
    'Olimp': 'Olimp',
    'igraciText': 'When they are not trying to win trophies around the World they are preparing with us. Next to 6 clay courts and 3 “US OPEN” hard courts, the players have at their disposal indoor and outdoor fitness facilities, World-class restaurant, and access to some of the best physiotherapists in the World without having to leave our center.',
    'Godina': 'Age',
    'Najbolji': 'Highest',
    'rang': 'rank',
    'Srbija': 'Serbia',
    'Hrvatska': 'Croatia',
    'Litvanija': 'Lithuania',
    'Engleska': 'England',
    'Bugarska': 'Bulgaria',
    'Rusija': 'Russia',
    'jankovaPorukaNasaPrica': 'The motto of my life and my academy is to',
    '- Nastaviš Da Kopaš -': ' -Keep Digging-',
    'jankovaPorukaTakmicari': 'The goal of our academy is to create an ecosystem in which attention to detail is second to none.',
    'staNudimo1': 'Tennis is a sport that requires a fast lifestyle full of various changes and challenges that every player has to keep up with.',
    'staNudimo2': 'Our team at the Tipsarevic Tennis Academy is committed to track and monitor every detail of the player`s progress. We are committed to paying such close attention to the technical, physical and mental well being, that no aspect or detail remains unaddressed. From taking care of their accommodation, nutrition, fitness, recovery, transportation and even tournament planning, we have purposefully created an ecosystem for our players to improve as much as possible.',
    'staNudimo3': 'Our academy’s program is designed to keep track of every aspect of the changes happening along the way. This is why every practice day at the academy is organized in a professional matter, with a goal to have as much information and data gathered in order for it to be communicated within the team. Each of our coaches is in charge of three to four players and each of them reports the players progress on weekly basis to our head coaches Mica Ratkovic, and Janko Tipsarevic. All of these weekly reports are catalogued in such a way that at the end of the month, or the end of the stay, they can be shared with the players as a point of reference for their progression.',
    'oProgramima': 'We offer different programs: weekly, monthly and yearly (with a bonus feature of 10 weeks of tournaments with a traveling coach) that you can choose from and decide for how long you would want to commit to us. Our end goal is quality, and we are confident that when you experience the level of it you will stay for as long as you are committed to your own progress.',
    'Nedeljni': 'Weekly',
    'Mesečni': 'Monthly',
    'Godišnji': 'Yearly',
    'Preuzmi brošuru': 'Download brochure',
    'Apliciraj za program': 'Apply for a program',
    'Kontakt forma': 'Contact form',
    "Email je neispravan": "Invalide email.",
    "Molimo vas popunite polja ime, prezime, email i vaša poruka": "Please fill in the fields name, surname, email and your message.",
    "Uspešno ste poslali email": "You have successfully sent an email.",
    'Prezime': 'Last name',
    'Telefon': 'Phone',
    'Pošalji': 'Send',
    'Vaša poruka': 'Your message',
    'skolaTenisaOpis1': 'Quality work at all levels',
    'skolaTenisaOpis2': 'Book a court + join a league',
    'skolaTenisaOpis3': 'Advanced programs of the TTA',
    'skolatenisaPragraf1': 'Our team gives special attention to organizing the Youth Tennis Program, because we are aware that the future of tennis is in their hands. For that reason our goal is to reach as many kids as possible, so that they can have access to this beautiful sport and through a unique tennis upbringing challenge themselves and push their boundaries. Our ambition is to help them be proud of themselves because of what they do, and respected because of how they do it.',
    'skolatenisaPragraf2': '',
    'skolatenisaPragraf3': 'The two principles of our youth program is ACCESIBILITY and QUALITY.',
    'skolatenisaPragraf4': 'At our Academy we are trying hard to give large numbers of kids access to proper sports upbringing, through various kinds of exercises and activities, while keeping their social aspect of group work in the center of the process. This is why we try to keep it ACCESIBLE.',
    'skolatenisaPragraf5': '<p>QUALITY of work is guaranteed through:<br /></p>',
    'skolaTenisaLista1': 'INTENSITY by working in small groups',
    'skolaTenisaLista2': 'EXPERIENCE of our coaching staff',
    'skolaTenisaLista3': 'INDIVIDUAL LESSONS available',
    'skolaTenisaLista4': 'FITNESS programs',
    'skolaTenisaLista5': 'FACILITIES that are World-class',
    'skolaTenisaLista6': 'EXTRA CLUB ACTIVITIES',
    'skolaTenisaLista7': 'SUPPORT from the Academy',
    'skolatenisaPragraf6': '<p>Our programs are available to:<br /></p>',
    'skolaTenisaLista8': 'Kids from 4-10 years old (red, orange and green level; 10s tennis)',
    'skolaTenisaLista9': 'Kids under 12, 14 & 16 years old.',
    'skolatenisaPragraf7': 'Our goal is to follow the progress from the youth tennis programs, all the way to the high performance program, which is why we have established a smooth transition with our coaches overseeing the whole process.',
    'skolatenisaPragraf8': 'Currently in our program at 11. April location there are 200+ kids. ' +
        'In 2020 Tipsarevic Tennis Academy established a partnership with the “Happy Kids” organization. ' +
        'In 2021. Youth Tennis Program will be expanding to our Olimp location as well.',
    'skolatenisaPragraf9': 'The team at our academy believes that our greatest strength is in love and passion for this sport, huge coaching experience, as well as our drive to improve ourselves.',
    'porukaSkolaTenisa': 'Join us, so that we can share with you our knowledge.',
    'nasaPricaText': 'Tipsarevic Tennis Academy was founded in 2013 by the former ATP player Janko Tipsarevic in his hometown of Belgrade, Serbia. Janko`s main goal, and a driving force behind the idea, has been to transfer his rich experience and knowledge to the next generation of players, in hopes that they can become the best possible versions of themselves on and off the court.',
    'nasaPricaText1': 'Because of his burning desire to achieve this goal, Janko has gathered a team of elite managers, tennis coaches and fitness trainers, all of which he has worked with and known throughout his long career, with one goal in mind. To bring quality of service and to provide an environment where attention to detail would be possible.',
    'nasaPricaText2': 'This is the reason Tipsarevic Tennis Academy has established itself as the best academy in Serbia, and in the region, offering a World class service by gathering the best possible coaching staff there is.',
    'nasTimText1': 'The heart of our academy is our team. Not only do we have the very best coaches in the World, but we also cover each and every aspect of the player`s life. From fitness experts, managing staff, and physiotherapists we don`t leave anything to chance.',
    'nasTimText2': 'Meet our team:',
    'Učitaj više vesti': 'Load more news',
    'Nazad na vesti': 'Back to news',
    'Ostale vesti': 'Other news',
    'Nazad na galerije': 'Back to galleries',
    'Pošaljite nam poruku': 'Send us a message',
    'GdeSeNalazimo': `We are located in Belgrade in two locations - the first tennis center under the auspices of this Academy is near the sports center "April 11" (three courts), the second "Tipsarevic - Olympus" with six courts of slag and three courts with hard surface in an excellent environment for the development of young tennis players, is located within SC Olimp in Zvezdara.`,
    'NAŠA GLAVNA LOKACIJA JE U SKLOPU SC "OLIMP-ZVEZDARA': 'MAIN LOCATION',
    'LOKACIJA NA KOJOJ VODIMO SVOJU TENISKU ŠKOLU': 'SECOND LOCATION',
    'Prikaži mapu ove lokacije': 'Show a map of this location',
    'rekreacijaParagraf1': 'Every day after 5 pm, our academy programs finish, and our recreational players begin. With a beautiful sun-setting atmosphere, tennis continues in its purest form late into the night hours. Next to the option to rent the courts and share them with your friends, you can also join the “Belgrade Tennis League”.',
    'rekreacijaTitle1': 'BELGRADE <br /> TENNIS LEAGUE',
    'rekreacijaBTL1': 'Belgrade Tennis League (BTL) is one of the biggest amateur tennis communities of tennis enthusiasts counting more than 150 members.',
    'rekreacijaBTL2': 'Although it is a non-for profit organization, this league is organized on an extremely high level, better than some professional sports organizations. With the help of digital weekly schedules available online and match results that are promptly updated, BTL also offers an app to track it all both on Android and IOS devices. Thanks to all that, as well as to the sincere enthusiasm and stern persistence, BTL has become a very important part of amateur tennis in Serbia.',
    'rekreacijaBTL3': 'Belgrade Tennis League is being held on the courts of TTA location: Olimp, where a new program of doubles has been implemented. This new format has emerged with the goal of promoting tennis doubles as ideal way of recreational tennis for all of its fans, no matter the age or gender.',
    'rekreacijaBTL4': 'What separates Belgrade Tennis League is its organization of MASTERS CUP at the end of each season where the best ranked players fight for the title.',
    'rekreacijaBTL5': 'For more information visit ',
    'jankovaPorukaRekreacija': 'Keep playing.',
    'skolaTenisaLista10': 'Attendees of the "Happy Kids" kindergartens',
    'translateCookies': 'This website uses cookies to enhance the user experience.',
    'dugmeSlazem': 'I agree',

}

export default en
