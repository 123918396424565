import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom'
import GalleryGrid from 'react-grid-gallery';
import { getGalleries, getGallery } from '../services/Services'
import Loader from '../utils/Loader'
import i18next from "i18next";

interface GalleryID {
    match?: {
        params?: {
            id?: number
        }
    }
}

interface GalleryCover {
    id?: number
    title?: string
    titleRS?: string
    coverImage?: {
        formats?: {
            small?: {
                url: string | null
            }
        }
        url?: string | null
    }
}

const Gallery = (props: GalleryID) => {

    const { t } = useTranslation();
    const [gallery, setGallery] = useState<object[]>()
    const [isLoading, setIsLoading] = useState<Boolean>(true)
    const [galleryTitle, setGalleryTitle] = useState({
        title: '',
        titleRS: ''
    })
    const [galleriesCover, setGalleriesCover] = useState<object[]>()

    interface Image {
        url: string,
        caption: string,
        formats: {
            small: {
                url: string,
                width: string,
                height: string
            },
            thumbnail: {
                url: string,
                width: string,
                height: string
            }
        }
    }

    useEffect(() => {
        getGallery(props?.match?.params?.id)
            .then(res => {
                res && res.data && setGalleryTitle({ title: res.data.title, titleRS: res.data.titleRS })
                const response = res && res.data && res.data.images.map((data: Image) => {
                    const thumb = data && data.formats && data.formats.small && data.formats.small.url ? data.formats.small.url : data.formats.thumbnail.url
                    return ({
                        src: `${process.env.REACT_APP_API}${data.url}`,
                        thumbnail: `${process.env.REACT_APP_API}${thumb}`,
                        caption: ''
                    })
                })
                setGallery(response)
                setIsLoading(false)
            }
            )
            .catch(err => console.log(err))

        getGalleries()
            .then(res => {
                res && res.data && setGalleriesCover([...res.data.reverse()])
            })
            .catch(err => console.log(err))

    }, [props?.match?.params?.id])

    return (<>
        <Helmet>
            <title>{`${t('Tipsarevic Tennis Academy | Galerije')}`}</title>
        </Helmet>
        <Header cssClass={"section-one"} />
        <div className="wrapper-inner otherpages section-one">
            {!isLoading ? <> <div className="content">
                <Link to={`/${i18next.language}/${t("galerije")}`} className="back-button">{t('Nazad na galerije')}</Link>
                <h1>{i18next.language === 'en' ? galleryTitle.title : galleryTitle.titleRS}</h1>
                {gallery && <GalleryGrid images={gallery} enableImageSelection={false} backdropClosesModal={true} imageCountSeparator={t('od')} />
                }
            </div>
                <div className="content">
                    <div className="other-galleries">
                        <h3>Ostale galerije</h3>
                        {galleriesCover && galleriesCover.map((data: GalleryCover, index: number) => {
                            if (index > 2) return null
                            return (
                                <Link to={`/${i18next.language}/${t("galerije")}/${data.id}`}><div key={index} className="news">
                                    <div className="news-photo">
                                        <img src={`${process.env.REACT_APP_API}${data && data.coverImage && data.coverImage.formats && data?.coverImage?.formats?.small ? data.coverImage.formats.small.url : data?.coverImage?.url}`} alt="" />
                                    </div>
                                    <div className="news-title">
                                        <h6>{i18next.language === 'en' ? data.title : data.titleRS}</h6>
                                    </div>
                                </div> </Link>
                            )
                        })
                        }
                    </div>
                </div>
            </>
                :
                <div className="content">
                    <Loader />
                </div>
            }
        </div>

        <Footer />
    </>
    )
}

export default Gallery
