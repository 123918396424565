import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Helmet } from "react-helmet-async";
import { useTranslation, Trans } from "react-i18next";
import { getCoaches } from '../services/Services'
import Loader from '../utils/Loader'

const Coaches = () => {

    const { t } = useTranslation();
    const [coaches, setCoaches] = useState<object[]>()
    const [isLoading, setIsLoading] = useState<Boolean>(true)

    useEffect(() => {

        getCoaches()
            .then(res => {
                const response = res && res.data
                response.sort((a: { sortID: number | null }, b: { sortID: number | null }) => (a.sortID != null ? a.sortID : Infinity) - (b.sortID != null ? b.sortID : Infinity))
                setCoaches([...response])
                setIsLoading(false)
            }
            )
            .catch(err => console.log(err))

    }, [])

    interface Coaches {
        picture?: {
            url: string
        }
        firstName?: string
        lastName?: string
        bio?: string
        bioRS?: string
        teamRole?: string
        flag?: {
            url: string | null
        }
    }

    return (<>
        <Helmet>
            <title>{`${t('Tipsarevic Tennis Academy | Tim')}`}</title>
        </Helmet>
        <Header cssClass={"section-one"} />
        <div className="wrapper-inner section-one otherpages">
            <div className="content">
                <h1>{t('Tim')}</h1>
                <p>{t('nasTimText1')}</p>
                <p>{t('nasTimText2')}</p>
            </div>
            <div className="content">
                <div className="players">
                    {!isLoading ? coaches && coaches.map((data: Coaches, index: number) => (<div key={index} className="player">
                        <div className="pl-photo">
                            <div className="team-photo"><img src={`${process.env.REACT_APP_API}${data.picture?.url}`} alt="" /></div>
                            <div className="stats stats-player">
                                <div className="stats-holder">
                                    <div className="position"><Trans>{data.teamRole}</Trans></div>
                                </div>
                            </div>
                        </div>
                        <div className="pl-data">
                            <span className="name">{data.firstName}</span>
                            <span className="name">{data.lastName}</span>
                        </div>
                    </div>
                    )) :
                        <Loader />
                    }
                </div>
            </div>
        </div>
        <Footer />
    </>
    )
}
export default Coaches
